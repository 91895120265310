/* components/VideoList.css */

.video-list {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.video-list h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
}

.video-item {
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.2s;
}

.video-item:hover {
  background-color: #f1f1f1;
}

.video-thumbnail {
  width: 168px;
  height: 94px;
  object-fit: cover;
  flex-shrink: 0;
}

.video-info {
  margin-left: 10px;
}

.video-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.video-channel {
  font-size: 12px;
  color: #606060;
}

.video-views {
  font-size: 12px;
  color: #606060;
}
