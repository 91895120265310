/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

.login-container {
  background-color: #ffffff;
  padding: 40px 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.logo {
  width: 75px;
  height: auto;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #202124;
}

.error-message {
  color: #d93025;
  margin-bottom: 15px;
  font-size: 14px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.input-group input:focus {
  border-color: #1a73e8;
}

.submit-button {
  background-color: #1a73e8;
  color: #ffffff;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #1669c1;
}

.toggle-link {
  margin-top: 20px;
}

.toggle-link p {
  font-size: 14px;
  color: #202124;
}

.toggle-link button {
  background: none;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.toggle-link button:hover {
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .login-container {
    padding: 30px 20px;
  }
}
